<template>
  <div class="h-full flex flex-col items-center justify-center">

    <SurLogo class="w-20 h-20" />

    <h1 class="my-10">Izberi temo</h1>

    <div class="flex flex-wrap justify-center items-center">
      <CategoryButton class="m-4" v-for="kategorija in kategorije" :key="kategorija.slug" :path="kategorija.path"
        :title="kategorija.naziv" @click="izberiTemo(kategorija)" />
    </div>
    <BottomMenu />
  </div>
</template>

<script>
// @ is an alias to /src
import BottomMenu from '@/components/BottomMenu.vue'
import CategoryButton from '@/components/CategoryButton.vue'
import SurLogo from '@/components/SurLogo.vue'

export default {
  data() {
    return {
      kategorije: [],
      zadnjeVprasanje: null
    }
  },
  name: 'HomeView',
  components: {
    BottomMenu,
    CategoryButton,
    SurLogo,
},
  methods: {
    izberiTemo(kategorija) {
      this.$router.push("/kategorija/" + kategorija.slug);
    }
  },
  async created() {
    if (!this.$introSeen) {
      this.$router.replace('/uvod')
      return;
    }
    if (!this.$userToken) {
      this.$router.replace("/profil")
      return;
    }
    if(this.$userToken.vloga == "Obiskovalec") {
      this.$error("Preden lahko brskaš prosim izpolni vsaj osnovni profil.");
      this.$router.replace("/profil");
      return;
    }
    if (this.$kategorije?.length) {
      this.kategorije = this.$kategorije;
    }
    else {
      this.kategorije = await this.$loadData("api/kategorija/?$filter=aktivna eq true");
      this.$kategorije = this.kategorije;
    }

    let zadnjaVprasanja = await this.$loadData("api/vprasanja/?$filter=objavljeno eq true&$orderby=id desc&$top=1")
    if (zadnjaVprasanja?.length) {
      this.zadnjeVprasanje = zadnjaVprasanja[0];
    }
  }
}
</script>

<style scoped>
.skrijScrollbar::-webkit-scrollbar {
  display: none;
}

.skrijScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>