import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import GlobalData from './globalData.vue'
import Toaster from "@meforma/vue-toaster";
import onlineProp from "./mixins/online.js";
// window.addEventListener('DOMContentLoaded', () => {
//     let displayMode = 'browser tab';
//     if (window.matchMedia('(display-mode: standalone)').matches) {
//       displayMode = 'standalone';
//     }
//     // Log launch display mode to analytics
//     alert('DISPLAY_MODE_LAUNCH:' + displayMode);
//   });

// let userToken = null;
// if(window.localStorage.userToken) {
//     userToken = JSON.parse(window.localStorage.userToken);
// }

const app = createApp(App)
    app.mixin(GlobalData);
    app.mixin(onlineProp);
    // app.config.globalProperties.$userToken = userToken;
    app.use(router);
    app.use(Toaster);
    app.mount('#app');
