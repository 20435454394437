<template>
    <div class="fixed left-[50%] w-52 -ml-24 bottom-5 px-4 py-0 bg-[#2D363C] text-xl text-white rounded-full flex justify-around">
      <router-link v-for="povezava in povezave" :key="povezava.cilj" :to="povezava.cilj">
        <span class="text-2xl inline-block border-zelena bottomLink" :class="povezava.dodatniClassi"/>
      </router-link>
      <!-- <a href="tel:116 111">
        <span class="text-2xl inline-block border-zelena bottomLink icon-sos" />
      </a> -->
    </div>
</template>

<script>
export default {
  data() {
    return {
      povezave: [
        { cilj: "/", dodatniClassi: "icon-sur-question", activeNames: ["Domov", "Uvod", "Kategorija", "VprasanjeNovo", "VprasanjeOgled"], active: false},
        { cilj: "/profil", dodatniClassi: "icon-sur-user", activeNames: ["Profil"], active: false},
        { cilj: "/about", dodatniClassi: "icon-sur-info", activeNames: ["About"], active: false}
      ],
    }
  },
  created() {
    for (let i = 0; i < this.povezave.length; i++) {
      let povezava = this.povezave[i];
      if(povezava.activeNames.indexOf(this.$route.name) > -1) {
        povezava.dodatniClassi += " bottomLinkActive"
      }
    }
  }
}
</script>

<style>
  .bottomLink {
    padding-top: 6px;
    padding-bottom: 6px; 
  }
  .bottomLinkActive {
    padding-bottom: 2px;
    border-bottom-width: 4px;
  }
</style>