<template>
  <div v-if="deferredPrompt" class="relative bg-body bg-fixed h-screen text-white flex flex-col items-center justify-around">
    <SurLogo class="w-20 h-20 my-20" />
    <button @click="install" class="rounded-full bg-white text-oranzna uppercase font-bold w-44 py-3 my-3">Namesti ŠUR</button>
    <button @click="dismiss" class="rounded-full border border-white text-white uppercase text-xs px-4 py-1 my-3">Nadaljuj v brskalniku</button>
  </div>
  <div v-else class="relative bg-body bg-fixed h-screen text-white">
    <div class="absolute overflow-hidden w-full h-full">
      <img src="@/assets/logo.svg" class="absolute" style="opacity: 0.14; bottom: -3%; left: -5%; width: 100%" />
      <img src="@/assets/logo.svg" class="absolute"
        style="opacity: 0.14; width: 100%; transform: matrix(1.6, 0 , 0, 1.6, -40, 50);" />
    </div>
    <div class="absolute overflow-y-scroll w-full h-full" id="scrollDiv">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <div v-if="updateExists" class="absolute z-[99] w-full bg-body flex flex-col items-center border-b-4 rounded-br-xl rounded-bl-xl">
      <SurLogo class="w-10 h-10 m-5"/>
      <div class="m-3">Nova različica aplikacije je na voljo</div>
      <button @click="refreshApp" class="rounded-full bg-white text-oranzna uppercase font-bold w-44 py-3 my-3 mb-12">Osveži</button>
    </div>
  </div>
</template>

<script>
import SurLogo from './components/SurLogo.vue';
import Update from './mixins/update.js';

export default {
  mixins:[Update],
  components: { SurLogo },
  data() {
      return {
          deferredPrompt: null
      };
  },
  created() {
      window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          if(sessionStorage && sessionStorage.getItem("declinedInstall")) {
            return;
          }
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
      });
      window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
      });
  },
  methods: {
      async dismiss() {
          this.deferredPrompt = null;
          if(sessionStorage) {
            sessionStorage.setItem("declinedInstall", "1");
          }
      },
      async install() {
          this.deferredPrompt.prompt();
      }
  },
};
</script>

<style>
/* route transition classes */
.route-enter-from {
  opacity: 0;
  /* transform: translateX(30px);   */
}

.route-enter-active {
  transition: all 0.2s ease-out;
}

.route-leave-to {
  opacity: 0;
  /* transform: translateX(-30px); */
}
</style>
