<template>  
  <div class="flex-col items-center catButton">
    <div @click="navigate" class="w-20 h-20 bg-darkboxbg rounded-3xl mx-3 my-1 flex justify-center items-center">
      <svg class="m-5" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <path class="fill-white" :d="path" />
      </svg>
    </div>
    <div class="text-center font-light text-xs catButtonText">{{title}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: { 
    'path': String, 
    'title': String
  }
}
</script>

<style>
</style>