<template>
  <div class="h-screen flex flex-col"
    :class="{ 'bg-uvod-1': activeView === 1, 'bg-uvod-2': activeView === 2, 'bg-uvod-3': activeView === 3 }">
    <div style="height: 13vh"></div>
    <svg style="height: 7vh;" class="mx-auto" viewBox="0 0 100 82" version="1.1" id="logo-sur"
      xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
      <defs id="defs3754" />
      <g id="logo" transform="matrix(3.7795276,0,0,3.7795276,-78.181789,-465.56867)">
        <path id="logo-sh"
          d="m 25.270089,127.30083 a 2.4726731,2.4726731 0 0 0 -0.728536,-1.75864 1.1576859,1.1576859 0 1 0 -1.63685,1.63758 0.17252894,0.17252894 0 0 1 0,0.24321 0.19101418,0.19101418 0 0 1 -0.243208,0 1.1848678,1.1848678 0 0 0 -1.63685,0 1.1573212,1.1573212 0 0 0 0,1.63685 2.4871714,2.4871714 0 0 0 4.246169,-1.75864 z" />
        <path id="logo-s"
          d="m 28.977649,135.08928 a 1.1207132,1.1207132 0 0 0 -0.12541,-0.013 2.45872,2.45872 0 1 1 0,-4.91744 1.1580461,1.1580461 0 1 0 0,-2.31573 4.7713675,4.7713675 0 0 0 -0.249369,9.53658 1.0928041,1.0928041 0 0 0 0.125409,0.0123 2.4589,2.4589 0 0 1 0,4.9178 1.1580461,1.1580461 0 0 0 0,2.31573 4.7713675,4.7713675 0 0 0 0.24937,-9.53657 z" />
        <path id="logo-r" data-name="Path 518"
          d="m 45.370073,136.06392 a 4.7673805,4.7673805 0 0 0 -3.209546,-8.30024 1.1576837,1.1576837 0 0 0 0,2.31501 2.4592623,2.4592623 0 1 1 0,4.91852 1.1319493,1.1319493 0 0 0 -0.209499,0.021 4.7778917,4.7778917 0 0 0 -4.355631,4.75288 1.1576837,1.1576837 0 0 0 2.315005,0 2.4589,2.4589 0 0 1 4.9178,0 1.1580461,1.1580461 0 0 0 2.315729,0 4.7652057,4.7652057 0 0 0 -1.773858,-3.7072 z" />
        <path id="logo-u" data-name="Path 519"
          d="m 39.905691,131.73547 c 0,-0.025 0.0076,-0.0493 0.0076,-0.075 v -7.34118 a 1.1580461,1.1580461 0 0 0 -2.31573,0 v 7.26651 a 2.0949942,2.0949942 0 1 1 -4.189988,0 v -5.66301 a 1.1580461,1.1580461 0 0 0 -2.31573,0 v 5.73768 c 0,0.0257 0.0058,0.0497 0.0076,0.075 a 4.4052872,4.4052872 0 0 0 8.807674,0 z" />
      </g>
    </svg>
    <div style="height: 6vh"></div>
    <div style="min-height: 44vh" @touchstart="touchStart" @touchend="touchEnd">
      <UvodSola v-if="activeView === 1" />
      <UvodUlica v-if="activeView === 2" />
      <UvodRazvoj v-if="activeView === 3" />
    </div>

    <div class="flex justify-center" style="height: 9vh">
      <hr class="sur-pager" :class="{ 'sur-pager-active': activeView === 1 }" />
      <hr class="sur-pager" :class="{ 'sur-pager-active': activeView === 2 }" />
      <hr class="sur-pager" :class="{ 'sur-pager-active': activeView === 3 }" />
    </div>

    <div class="flex justify-center">
      <button class="rounded-full bg-white text-oranzna uppercase font-bold w-44 py-3"
        @click="btnNaprejClick">{{ btnText }}</button>
    </div>
  </div>
</template>

<script>
import UvodSola from '@/components/Uvod/UvodSola.vue'
import UvodUlica from '@/components/Uvod/UvodUlica.vue'
import UvodRazvoj from '@/components/Uvod/UvodRazvoj.vue'

export default {
  name: 'UvodView',
  components: {
    UvodSola,
    UvodUlica,
    UvodRazvoj
  },
  data() {
    return {
      activeView: 1,
      touchStartX: null,
      touchStartY: null,
      touchStartTime: null,
    }
  },
  computed: {
    btnText() { return this.activeView === 3 ? "Razumem" : "Naprej" }
  },
  methods: {
    _moveNaprej() {
      if (this.activeView == 3) {
        this.$introSeen = true;
        this.$router.replace('/');
      }
      else {
        this.activeView++;
      }
    },
    _moveNazaj() {
      if (this.activeView > 1) {
        this.activeView--;
      }
    },
    btnNaprejClick() {
      this._moveNaprej();
    },
    touchStart(e) {
      this.touchStartX = e.touches[0].pageX;
      this.touchStartY = e.touches[0].pageY;
      this.touchStartTime = new Date();
    },
    touchEnd(e) {
      let moveX = e.changedTouches[0].pageX - this.touchStartX;
      let moveY = e.changedTouches[0].pageY - this.touchStartY;
      let moveTime = new Date() - this.touchStartTime;
      if (Math.abs(moveX) > 30 && Math.abs(moveY) < 30 && moveTime < 1000) { //vodoravno več kot 30pik, navpično manj kot 30px in v 1 sekundi
        if (moveX > 0) { //swipe desno
          this._moveNazaj();
        }
        else {
          this._moveNaprej();
        }
      }
    }
  }
}
</script>

<style>
</style>