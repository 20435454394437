import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UvodView from '../views/UvodView.vue'

const routes = [
  {
    path: '/',
    name: 'Domov',
    component: HomeView
  },
  {
    path: '/uvod',
    name: 'Uvod',
    component: UvodView
  },
  {
    path: '/kategorija/:slug',
    name: 'Kategorija',
    component: () => import('../views/KategorijaView.vue')
  },
  {
    path: '/novo',
    name: 'VprasanjeNovo',
    component: () => import('../views/VprasanjeNovo.vue')
  },
  {
    path: '/vprasanje/:id',
    name: 'VprasanjeOgled',
    component: () => import('../views/VprasanjeOgled.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/profil',
    name: 'Profil',
    component: () => import('../views/ProfilView.vue')
  }
  // {
  //   path: '/uvod',
  //   name: 'Uvod',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
