<script>
window.globalData = {
  showBottomMenu: false,
  svcBaseUrl: "/",
  userToken: null,
  kategorije: []
}

export default {
  computed: {
    $showBottomMenu: {
      get() { return window.globalData.showBottomMenu; },
      set(newValue) { window.globalData.showBottomMenu = newValue; }
    },
    $introSeen: {
      get() { return window.localStorage.introSeen || false; },
      set(newValue) { window.localStorage.introSeen = newValue; }
    },
    $userToken: {
      get() { 
        if(window.globalData.userToken) return window.globalData.userToken;
        if(window.localStorage.userToken) {
          window.globalData.userToken = JSON.parse(window.localStorage.userToken);
        }
        return window.globalData.userToken;
      },
      set(newValue) { 
        window.globalData.userToken = newValue; window.localStorage.userToken = newValue ? JSON.stringify(newValue) : null }
    },
    $svcBaseUrl: {
      get() { return window.globalData.svcBaseUrl }
    },
    $kategorije: {
      get() {         
        return window.globalData.kategorije;
      },
      set(newValue) { 
        window.globalData.kategorije = newValue
      }
    }
  },
  methods: {
    $error(msgString) { 
      this.$toast.error(msgString);
      setTimeout(5000, function() { this.$toast.clear(); })
    },
    $success(msgString) { 
      this.$toast.success(msgString);
      setTimeout(5000, function() { this.$toast.clear(); })
    },
    async $loadData(relativeUrl, payload, returnRawResponse) {
      if (this.$userToken == null || this.$userToken.token == null) {
        this.$error("Niste prijavljeni!");
        return;
      }
      if (payload == null) {
        payload = {};
      }
      if (payload.headers == null || payload.headers == undefined) {
        payload.headers = {};
      }
      payload.headers.Authorization = "Bearer " + this.$userToken.token;

      if(payload.headers["Content-Type"] == null || payload.headers["Content-Type"] == undefined)
      {
        payload.headers["Content-Type"] = "application/json";
      }

      if (payload.method == null || payload.method == undefined) {
        payload.method = "GET";
      }

      try {
        var response = await fetch(this.$svcBaseUrl + relativeUrl, payload);
      }
      catch(error) {
        if(!navigator.onLine) {
          this.$error("Nimate internetne povezave.");
          return;
        }
        if(error.message?.toLowerCase().indexOf("failed to fetch") > -1) {
          this.$error("Niste prijavljeni!");
          this.$userToken = null;
          this.$router.push("/");
          return;
        }
      }

      if (returnRawResponse === true) {
        return response;
      }

      switch (response.status) {
        case 401:
          this.$error("Niste prijavljeni!");
          this.$userToken = null;
          this.$router.push("/");
          return;
        case 403:
          this.$error("Nimate pravic");
          return;
        case 500:
          this.$error("Napaka strežnika.");
          return;
        case 200: //ok
        case 201: //created
        case 204: //no content
          break;
        default:
          this.$error(`Napaka pri nalaganju podatkov (status ${response.status})`)
          return;
      }

      var data = await response.json();

      return data;
    }
  }
}
</script>