export default {
    data() {
        return {
            online: navigator.onLine,
            _onlineHandler: null
        }
    },
    created() {
        let _this = this;
        _this._onlineHandler = function() {
            _this.online = navigator.onLine;
            console.log("ONLINE:" + _this.online);
        }
        window.addEventListener("online", this._onlineHandler);
        window.addEventListener("offline", this._onlineHandler);
    },
    beforeUnmount() {
        window.removeEventListener("online", this._onlineHandler);
        window.removeEventListener("offline", this._onlineHandler);
    }
}